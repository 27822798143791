<template>
  <div id="agency">
    <van-sticky :offset-top="0">
      <div class="header">
        <van-row gutter="10">
          <van-col span="10" class="header-title" @click="onClickLeft">
            <svg-icon
              :data_iconName="'back-default'"
              :className="'back_icon'"
            />旅行社管理
          </van-col>
          <van-col span="14" class="search">
            <input
              type="text"
              class="search-input"
              v-model="query.name"
              @blur="onRefresh"
            />
            <svg-icon
              :data_iconName="'search'"
              :className="'search-input-icon'"
            />
          </van-col>
        </van-row>
      </div>
    </van-sticky>
    <van-row class="tabs-container">
      <van-col
        span="8"
        v-for="(item, index) in tabs"
        :key="index"
        :class="[item.type == query.status ? 'tab-active' : 'tab-default']"
        @click="onTabClick(item)"
        >{{ item.text }}</van-col
      >
    </van-row>
    <div class="agency-body">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-if="list.length > 0"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="get_travel_companies"
        >
          <van-row
            class="agency-body-item"
            v-for="(item, index) in list"
            :key="index"
            @click="onItemClick(item)"
          >
            <van-col span="5">
              <van-image
                class="item-avatar"
                round
                :src="item.avatar ? item.avatar : ''"
                ><template v-slot:error
                  ><span style="font-size: 10px">暂无头像</span></template
                ></van-image
              >
            </van-col>
            <van-col span="19">
              <div class="item-name" style="display: flex">
                <div
                  style="
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: block;
                    max-width: 80%;
                  "
                >
                  {{ item.name }}
                </div>
                <div style="display: inline-block; max-width: 30%">
                  <van-rate
                    v-if="item.star"
                    v-model="item.star"
                    :size="10"
                    color="#FFAA00"
                    void-icon="star"
                    gutter="2px"
                    void-color="#fff"
                    readonly
                    allow-half
                  />
                  <van-rate
                    v-else
                    :size="10"
                    color="#f5f5f5"
                    void-icon="star"
                    gutter="2px"
                    void-color="#fff"
                    readonly
                    allow-half
                    value="5"
                  />
                </div>

                <!-- <div class="state">{{ item.status_text }}</div> -->
              </div>
              <div class="item-sub-text">
                法人:
                {{ item.legal_person ? item.legal_person : "暂无" }}
              </div>
              <div class="item-sub-text">
                电话：{{ item.telephone ? item.telephone : "暂无" }}
              </div>
            </van-col>
          </van-row>
        </van-list>
        <van-empty
          v-if="list.length == 0 && loading == false"
          description="暂无数据"
        />
      </van-pull-refresh>
    </div>
    <Tarbar :active="0"></Tarbar>
  </div>
</template>

<script>
import Tarbar from "../components/Tarbar.vue";
export default {
  name: "agency",
  components: {
    Tarbar,
  },
  data() {
    const _self = this;
    return {
      list: [],
      loading: true,
      tabs: [
        {
          id: 0,
          text: "已审核",
          type: 1,
        },
        {
          id: 0,
          text: "未审核",
          type: 0,
        },
        {
          id: 0,
          text: "未通过",
          type: 2,
        },
      ],
      finished: false,
      refreshing: false,
      query: {
        page: 1,
        name: "",
        status: _self.$route.query.type ? _self.$route.query.type : "1",
      },
    };
  },
  methods: {
    onClickLeft() {
      this.$router.push("/");
    },
    onTabClick(item) {
      this.query.status = item.type;
      this.onRefresh();
    },
    onItemClick(item) {
      this.$router.push({
        path: "/agency/item",
        query: {
          id: item.id,
          type: this.query.status,
        },
      });
    },
    async get_travel_companies() {
      const res = await this.$apis.get_travel_companies(this.query);
      if (res.next_page) {
        this.query.page++;
        this.loading = false;
        this.refreshing = false;
      } else {
        this.finished = true;
        this.loading = false;
        this.refreshing = false;
      }
      this.list = [...this.list, ...res.data];
    },
    onSearch() {
      // this.list = [];
    },
    onRefresh() {
      // 清空列表数据
      this.list = [];
      this.finished = false;
      this.query.page = 1;
      this.loading = true;
      this.get_travel_companies();
    },
  },
  created() {},
  activated() {
    this.onRefresh();
  },
};
</script>

<style scoped>
#agency {
  height: 100vh;
  /* padding-top:10vh; */
  overflow-y: hidden;
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.search {
  position: relative;
}
.search-input {
  border-radius: 14.5px;
  border-radius: 14.5px;
  height: 0.6rem;
  width: 100%;
  background: rgba(216, 216, 216, 0.21);
  padding-right: 1rem;
  padding-left: 0.3rem;
  font-size: 0.4rem;
  color: #fff;
  vertical-align: middle;
  line-height: 0.6rem;
}
.search-input-icon {
  position: absolute;
  right: 0.2rem;
  top: 50%;
  transform: translateY(-0.1rem);
  font-size: 0.4rem;
}
.agency-body {
  background: #ffff;
  height: 80vh;
  padding: 0.4rem;
  overflow-y: scroll;
  padding-bottom: 4rem;
  position: fixed;
  top: 23vh;
  left: 0vh;
  width: 100vw;
}
.agency-body-item {
  width: 100%;
  height: 1.6rem;
  position: relative;
  z-index: 10;
}
.agency-body-item .item-avatar {
  overflow: hidden;
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 50%;
}
.agency-body-item .item-avatar img {
  height: 100%;
  height: 100%;
}
.agency-body-item .item-name {
  font-size: 16px;
  color: #333;
  font-weight: bold;
}
.agency-body-item .item-sub-text {
  color: #666;
  font-size: 12px;
}
.state {
  background: rgba(64, 169, 255, 0.16);
  border-radius: 11px;
  border-radius: 11px;
  text-align: center;
  display: inline-block;
  padding: 2px 5px;
  color: #40a9ff;
  font-size: 12px;
  margin-left: 10px;
}
.tabs-container {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 10vh;
  line-height: 10vh;
  background-color: #fff;
  /* margin-top: 0.4rem; */
  position: fixed;
  top: 13vh;
  left: 0;
  width: 100%;
}
.tab-default {
  text-align: center;
  font-size: 0.3rem;
  color: #999;
  font-weight: bold;
}
.tab-active {
  text-align: center;
  font-size: 0.3rem;
  color: #333;
  font-weight: bold;
}
</style>
